.TrackGridContainer {
  flex-grow: 1;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  /* background-color: #111111; */
  /* transform: scale(0.96); */
}

.swiper-button-prev,
.swiper-button-next {
  color: white !important;
}

.sw .swiper-slide.swiper-slide-prev .swiper-slide-shadow-left,
.swiper-slide.swiper-slide-next .swiper-slide-shadow-left {
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0)
  );
}

.swiper-slide.swiper-slide-prev .swiper-slide-shadow-right,
.swiper-slide.swiper-slide-next .swiper-slide-shadow-right {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0)
  );
}

.swiper-slide.swiper-slide-active {
  transition-duration: 0ms;
  /* transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) scale(1.05) !important; */
  z-index: 1;
}

.TrackGridContainer .carouselContainer {
  overflow: hidden;
  width: 100%;
  height: 100%;
  /* margin-top: 25%; */
  display: flex;
  align-items: center;
  position: absolute;
}
.TrackGridContainer .carouselContainer .swiper-container {
  margin-top: 65px;
  width: 1000px;
  padding-bottom: 70px;
}

.TrackGridContainer .carouselContainer .swiper-container .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.TrackGridContainer
  .carouselContainer
  .swiper-container
  .swiper-slide
  .trackImage {
  display: block;
  width: 300px;
}

.TrackGridContainer .carouselContainer .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
  background: rgba(0, 0, 0, 0);
  transition: background 0.25s ease;
}

.TrackGridContainer .carouselContainer .swiper-slide-active:hover .overlay {
  display: block;
  background: rgba(0, 0, 0, 0.3);
}

.swiper-pagination-bullet {
  background: white !important;
}

.TrackGridContainer .carouselContainer .playButton {
  opacity: 0;
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 150px;
  height: 150px;
  background: url("../../media/PlayButton.png") no-repeat scroll center center /
    50px 50px;
  pointer-events: none;
}

.TrackGridContainer .carouselContainer .playButton.playing {
  background: url("../../media/PauseButton.png") no-repeat scroll center center /
    50px 50px;
}

.TrackGridContainer .carouselContainer .playButton.playing:hover {
  background: url("../../media/PauseButton.png") no-repeat scroll center center /
    50px 50px;
}

.TrackGridContainer .swiper-slide-active:hover .playButton {
  opacity: 1;
  pointer-events: fill;
}

/* Player CSS */
.TrackGridContainer .player {
  background-color: orange;
  width: 100%;
  height: 80px;
  bottom: 0;
  position: absolute;
  display: flex;
}

.TrackGridContainer .player .currentImageContainer {
  width: 80px;
  height: 80px;
}

.TrackGridContainer .player .currentImageContainer .currentImage {
  width: 100%;
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.TrackGridContainer .player .playerStatus {
  background-color: peachpuff;
  margin: auto;
  width: 300px;
  text-align: center;
}

.TrackGridContainer .player .playButtonContainer {
  margin-left: 50px;
}

/*  */
.infoContainer {
  width: 100%;
}

.infoContainer .songInfoContainer {
  color: white;
  position: absolute;
  top: 60px;
  left: 50px;
}

.infoContainer .songInfoContainer .songName {
  width: 350px;
  font-weight: 700;
  font-size: 30px;
  font-family: sans-serif;
}

.infoContainer .songInfoContainer .artistName {
  width: 200px;
  font-weight: 700;
  font-size: 15px;
  font-family: sans-serif;
  padding-top: 15px;
}
