.Container {
  position: relative;
  height: 100vh;
  background: #003353;
  background: -webkit-linear-gradient(to right, #003353, #c9c9c9);
  background: linear-gradient(to right, #003353, #c9c9c9);

  /* background: #536976;
  background: -webkit-linear-gradient(
    to right,
    #292e49,
    #536976
  ); 
  background: linear-gradient(
    to right,
    #292e49,
    #536976
  ); */
}

.Container.Login .loginMain {
  width: 100%;
  position: relative;
}

.Container.Login .loginMain .logoContainer {
  display: flex;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  width: 300px;
  top: 65px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 3;
}

.Container.Login .loginMain .logoContainer img {
  width: 100%;
}

.Container.Login .loginMain .textLoop {
  display: inline;
  position: absolute;
  width: 200px;
  z-index: 3;
  top: 200px;
  left: 50px;
  font-size: 50px;
  line-height: 1.4;
  color: #f1faee;
  font-weight: 900;
}

.buttonContainer {
  display: flex;
  position: absolute;
  z-index: 3;
  width: 100%;
  justify-content: center;
  /* background-color: orange; */
  bottom: 90px;
}

.buttonContainer .icon {
  width: 32px;
  height: 32px;
  display: inline;
}

.buttonContainer a {
  /* text-decoration: none;
  font-size: 17px;
  line-height: 1.2; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 400px;
  padding: 18px 40px 14px;
  font-family: "Teko", sans-serif;
  font-weight: 900;
  /* color: white;
  font-weight: 420; */
  background-color: #1db954;
  text-decoration: none;
}

.buttonContainer a p {
  color: white;
  font-weight: 420;
  text-decoration: none;
  font-size: 25px;
  line-height: 1.2;
  margin-left: 12px;
}

.buttonContainer a:hover {
  transform: scale(1.02);
  box-shadow: 0 0 0 2em #16873e inset;
}

.backgroundCover {
  position: absolute;
  background-color: #484848;
  opacity: 0.4;
  width: 100vw;
  height: 100vh;
  z-index: 2;
}

.LoginContainer {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

/* .LoginContainer .background {
  filter: grayscale(100%);
  margin-top: -60px;
} */
