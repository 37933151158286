.AverageChartContainer {
  /* background-color: #f1faee; */
  margin-top: 16px;
  padding: 30px;
  height: 100%;
  box-sizing: border-box;
  background-color: #151515;
  border-left: 0.5px solid white;
  border-top: 0.5px solid white;
  border-top-left-radius: 30px;
  transform: scale(0.95);
}

.AverageChartContainer .radarChart {
  padding-left: 5px;
}

.AverageChartContainer .chartTitle {
  font-size: 30px;
  padding-bottom: 20px;
  color: white;
  font-weight: 700;
}

.AverageChartContainer .recOptions {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AverageChartContainer .recOptions .optionButton {
  color: white;
  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;
  cursor: pointer;
  width: 300px;
  height: 50px;
  margin-bottom: 20px;
  background-color: transparent;
  border: 1px solid white;
}
