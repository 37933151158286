/* .mcontainer .mainContainer .currentSongContainer .styleButton */
.styledButton {
  color: white;
  font-size: 16px;
  font-weight: 800;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  height: 50px;
  margin-bottom: 15px;
  border: solid 1px white;
  background-color: transparent;
}

.mcontainer {
  padding-top: 70px;
  background-color: #e0e1dd;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  box-sizing: border-box;
}

.mcontainer .mainContainer {
  max-width: 90%;
  width: 90%;
  background-color: #e0e1dd;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  transform: scale(0.93);
}

.mcontainer .mainContainer .currentSongContainer {
  background-color: #1d3557;
  padding: 30px;
  height: 530px;
  margin-right: 30px;
  border-top-right-radius: 50px;
  width: 300px;
  min-width: 280px;
  min-height: 530px;
}

.mcontainer .mainContainer .currentSongContainer .songTitle {
  font-size: 30px;
  line-height: 1.4;
  color: white;
  font-weight: 900;
  word-wrap: break-word;
  text-overflow: ellipsis;
}

.mcontainer .mainContainer .currentSongContainer .artistName {
  font-size: 21px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  color: white;
  padding-bottom: 10px;
  font: 700;
}

.mcontainer .mainContainer .currentSongContainer .saveContainer {
  right: 0px;
}

.mcontainer
  .mainContainer
  .currentSongContainer
  .saveContainer
  .material-icons {
  box-sizing: border-box;
  color: white;
  font-size: 37px;
  padding-top: 7px;
  cursor: pointer;
}

.mcontainer .mainContainer .currentSongContainer .albumImageContainer {
  margin-bottom: 15px;
}

.mcontainer .mainContainer .currentSongContainer .albumImageContainer {
  width: 300px;
  height: 300px;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.mcontainer
  .mainContainer
  .currentSongContainer
  .albumImageContainer
  .albumImage {
  width: 100%;
  height: 100%;
  border-radius: 7px;
  /* border: thin solid white; */
}

.mcontainer .mainContainer .sliderContainer {
  background-color: #de6a78;
  /* background-color: #ee8995; */
  padding: 30px;
  height: 60%;
  margin-right: 30px;
  border-top-right-radius: 50px;
  min-width: 300px;
  min-height: 530px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.mcontainer .mainContainer .sliderContainer .sliders #reset {
  margin-top: 10px;
  height: 30px;
  cursor: pointer;
  margin-left: 80%;
  font-weight: 700;
  color: white;
  font-size: 16px;
  background-color: transparent;
  cursor: pointer;
  border: 2px solid white;
}

.rc-slider-handle {
  border-color: white;
  background-color: white;
}

.rc-slider-handle:hover {
  border-color: black;
}

.rc-slider-dot-active {
  border-color: black;
}

.mcontainer .mainContainer .sliderContainer .descContainer {
  margin-bottom: 30px;
  width: 100%;
}

.mcontainer .mainContainer .sliderContainer .descContainer p {
  font-size: 30px;
  line-height: 1.4;
  color: white;
  font-weight: 900;
}

.mcontainer .mainContainer .sliderContainer .slider {
  width: 280px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.mcontainer .mainContainer .sliderContainer .slider .range {
  margin-top: 4px;
  margin-right: -3%;
  color: white;
  float: right;
}

.mcontainer .mainContainer .sliderContainer .slider .desc {
  font-size: 17px;
  font-weight: 500;
  line-height: 1.4;
  color: white;
  padding-bottom: 10px;
}

.mcontainer .mainContainer .radarContainer {
  background-color: #3e9e73;
  /* background-color: #ad7f39; */
  padding: 30px;
  /* height: 60%; */
  margin-right: 30px;
  border-top-right-radius: 50px;
  min-width: 300px;
  min-height: 530px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.radar {
  margin-top: 20px;
}

.mcontainer .mainContainer .radarContainer .chartTitle {
  font-size: 30px;
  line-height: 1.4;
  color: white;
  font-weight: 900;
  width: 100%;
  margin-bottom: 20px;
}

.mcontainer .mainContainer .radarContainer .navButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}

.mcontainer .mainContainer .radarContainer .navButtons .optionButton {
  color: white;
  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;
  cursor: pointer;
  width: 300px;
  height: 50px;
  margin-bottom: 15px;
  border: 1px solid white;
  background-color: transparent;
}
